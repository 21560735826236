$(document).ready(function() {

    var $menuBttn = $("#logo-bttn");
    var $menu = $("#menu");
    var $overlay = $("#overlay");

    $menuBttn.click(function() {
        $menu.toggle();
        $overlay.toggle();
    });

    $overlay.click(function() {
        $menu.toggle();
        $overlay.toggle();
    });
});