$(document).ready(function() {

    var $addr = $("#cllps-addr");
    $("#cllps-bttn").click(function(e) {
        $addr.toggle();
    });

    $(window).resize(function(e) {
        if (e.target.innerWidth >= 860) {
            $addr.show();
        }
    });
});